import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { row, section } from "./logoRow.module.css";
import { Link } from "gatsby";

const LogoRow = () => {
  return (
    <Container fluid className={section}>
      <Row className={row}>
        <Col sm={2}>
          <Link to="https://www.mostsailing.com/en/home/" target="_blank">
            <StaticImage
              src="../../images/most-sailing.png"
              className="img-fluid mx-auto d-block"
              alt="Most sailing logo"
              imgStyle={{ objectFit: "contain" }}
              height={45}
            />
          </Link>
        </Col>
        <Col sm={2}>
          <Link to="https://www.adventure-charter.hr/" target="_blank">
            <StaticImage
              src="../../images/adventure-charter.png"
              className="img-fluid mx-auto d-block"
              alt="Adventure charter logo"
              imgStyle={{ objectFit: "contain" }}
              height={45}
            />
          </Link>
        </Col>
        <Col sm={2}>
          <Link to="https://sailme-charter.com/" target="_blank">
            <StaticImage
              src="../../images/sailme.png"
              className="img-fluid mx-auto d-block"
              alt="Cata sailing logo"
              imgStyle={{ objectFit: "contain" }}
              height={75}
            />
          </Link>
        </Col>
        <Col sm={2}>
          <Link to="https://www.sail-croatia.com/" target="_blank">
            <StaticImage
              src="../../images/sail-croatia.png"
              className="img-fluid mx-auto d-block"
              alt="Sail croatia logo"
              imgStyle={{ objectFit: "contain" }}
              height={45}
            />
          </Link>
        </Col>
      </Row>
      <Row className={row}>
        <Col sm={2}>
          <Link to="https://www.hermesyachting.com/" target="_blank">
            <StaticImage
              src="../../images/hermes.png"
              className="img-fluid mx-auto d-block"
              alt="Hermes yachting logo"
              imgStyle={{ objectFit: "contain" }}
              height={45}
            />
          </Link>
        </Col>
        <Col sm={2}>
          <Link to="https://seafarersailing.co.uk/" target="_blank">
            <StaticImage
              src="../../images/seafarer.png"
              className="img-fluid mx-auto d-block"
              alt="Seafarer logo"
              imgStyle={{ objectFit: "contain" }}
              height={55}
            />
          </Link>
        </Col>
        <Col sm={2}>
          <Link to="https://archonyachting.com/" target="_blank">
            <StaticImage
              src="../../images/archon.png"
              className="img-fluid mx-auto d-block"
              alt="Archon Yachting logo"
              imgStyle={{ objectFit: "contain" }}
              height={35}
            />
          </Link>
        </Col>
        <Col sm={2}>
          <Link to="https://www.yates-mallorca.com/en//" target="_blank">
            <StaticImage
              src="../../images/yates-mallorca.png"
              className="img-fluid mx-auto d-block"
              alt="Yates Mallorca logo"
              imgStyle={{ objectFit: "contain" }}
              height={35}
            />
          </Link>
        </Col>
        <Col sm={2}>
          <Link to="https://www.aqua-charters.com/" target="_blank">
            <StaticImage
              src="../../images/aqua-charters.png"
              className="img-fluid mx-auto d-block"
              alt="Aqua Charters logo"
              imgStyle={{ objectFit: "contain" }}
              height={35}
            />
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default LogoRow;
